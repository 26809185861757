<template>
  <div class="edit-predicted-wo-actual-popup-flow">
    <edit-predicted-w-o-actual v-if="currentComponent === componentNames.editPredicted"
                                         :predicted-transaction="predictedTransaction"
                                         :close-action="closeAction"
                                         :change-expense-category="changeExpenseCategory"
                                         :fixed-categories="fixedCategories" />

    <edit-predicted-transaction-summary v-if="currentComponent === componentNames.editSummary"
                                     :predicted-transaction="predictedTransaction"
                                     :old-expense-category="oldExpenseCategory"
                                     :new-expense-category="newExpenseCategory"
                                     :close-action="closeAction"
                                     :back-action="back" />
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import Segment from '@/Segment';
import consts from '@/constants/cashflow-view';
import EditPredictedWOActual from '../EditPredictedWOActual.vue';
import EditPredictedTransactionSummary from '../EditPredictedTransactionSummary.vue';

const componentNames = {
  editPredicted: 'edit-predicted',
  editSummary: 'edit-summary',
};

export default {
  name: 'EditPredictedWOActualFlow',
  components: {
    EditPredictedWOActual,
    EditPredictedTransactionSummary,
  },
  props: {
    predictedTransaction: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentComponent: componentNames.editPredicted,
      oldExpenseCategory: this.predictedTransaction.expense,
      newExpenseCategory: null,
    };
  },
  computed: {
    ...mapGetters('cashflowView', ['fixedExpenseCategories', 'fixedIncomeCategories']),
    componentNames() {
      return componentNames;
    },
    fixedCategories() {
      const categories = this.predictedTransaction.isIncome
        ? [...this.fixedIncomeCategories, ...consts.TRANSFERABLE_FIXED_INCOME_CATEGORIES]
        : [...this.fixedExpenseCategories, ...consts.TRANSFERABLE_FIXED_EXPENSE_CATEGORIES];
      return _.chain(categories)
        .uniq()
        .sortBy()
        .reject(category => category === consts.OTHER_CATEGORY)
        .reject(category => consts.FIXED_SAVING_CATEGORY_NAMES.includes(category))
        .concat(consts.OTHER_CATEGORY)
        .value();
    },
  },
  methods: {
    setNewExpenseCategory(newExpenseCategory) {
      this.newExpenseCategory = newExpenseCategory;
    },
    closeAction() {
      Segment.trackUserInteraction('EditPredictedWOActualFlow_Closed', {
        predictedTransaction: this.predictedTransaction,
      });
      this.$emit('close');
    },
    back() {
      this.currentComponent = componentNames.editPredicted;
    },
    changeExpenseCategory(newExpenseCategory) {
      this.newExpenseCategory = newExpenseCategory;
      this.currentComponent = componentNames.editSummary;
    },
  },
};
</script>
